<template>
  <div>
    <div class="headerContainer" v-if="accordion">
      <div class="headerLeft bold">
        <h2 class="pn-l">{{ $t('payment.info.header') }}</h2>
      </div>
      <div class="headerRight" @click="show = !show">
        <pn-button class="small"
                   :icon="show ? 'angle-small-up' : 'angle-small-down'"
                   variant="outlined"
                   appearance="light"
                   small="true">
          {{ setText() }}
        </pn-button>

      </div>
    </div>

    <div class="details" v-if="!accordion || show">
      <div class="row">
        <span class="key">{{ $t('payment.info.amount') }}</span>
        <span class="value">{{ amount }} {{ paymentOption.currency }}</span>
      </div>
      <div class="row" v-if="paid && payment.executionDate && provider === 'TINK'">
        <span class="key">{{ $t('payment.info.tink.scheduled') }}</span>
        <span class="value">{{ dateFormat(payment.executionDate) }}</span>
      </div>
      <div class="row">
        <span class="key">{{ $t('payment.info.dueDate') }}</span>
        <span class="value">{{ dateFormat(payment.dueDate) }}</span>
      </div>
      <div class="row" v-if="paid">
        <span v-if="this.provider === 'TINK'" class="key">{{ $t('payment.info.tink.sent') }}</span>
        <span v-if="this.provider === 'SWISH' || this.provider === 'VIPPS'" class="key">{{ $t('payment.info.direct.sent') }}</span>
        <span class="value">{{ dateFormat(payment.updatedTimestamp) }}</span>
      </div>
      <div class="row" v-if="paid">
        <span v-if="this.provider === 'TINK'" class="key">{{ $t('payment.info.tink.account') }}</span>
        <span v-if="this.provider === 'TINK'" class="value" v-html=formatAccountNr(payment.externalPayment.paymentSource)></span>
        <span v-if="this.provider === 'SWISH'" class="key">{{ $t('payment.info.direct.account') }}</span>
        <span v-if="this.provider === 'SWISH'" class="value" v-html=formatAccountNr(payment.externalPayment.paymentSource)></span>
      </div>
      <div class="row">
        <span class="key">{{ $t('payment.info.recipient') }}</span>
        <span class="value">{{ payment.recipient }}</span>
      </div>
      <div class="row" v-if="!paid">
        <span class="key">{{ $t('payment.info.ocr') }}</span>
        <span class="value">{{ ocr }}</span>
      </div>
      <div class="row" v-show="payment.invoiceUrl">
        <span class="key">{{ $t('payment.info.invoice') }}</span>
        <span class="value">
          <a class="pdf" :href="payment.invoiceUrl" target="_blank">
            {{ $t('payment.info.showInvoice') }}
          </a>
        </span>
      </div>
    </div>
  </div>
</template>
<script>

  import { formatDate } from "../services/dateService";

  export default {
    name: 'PaymentInfo',
    props: ['payment', 'accordion', 'provider', 'amount', 'ocr', 'paymentOption'],
    data() {
      return {
        paid: false,
        show: false
      };
    },
    methods: {
      dateFormat(value) {
        return formatDate(value, this.$t);
      },
      formatAccountNr(account) {
        return (
          "<strong>&middot;&middot;&middot;&middot; &middot;&middot;&middot;</strong> " +
          account.slice(account.length - 3)
        );
      },
      setText() {
        return this.$t('payment.' + (this.show ? "hide" : "show"));
      }
    },
    mounted() {
      if (["APPROVED"].indexOf(this.payment.status) !== -1) {
        this.paid = true;
      }
    }
  }

</script>

<style scoped>

  .headerContainer {
    width: 100%;
    display: inline-flex;
    margin-top: 2.3em;
    flex-direction: row;
  }

  .headerLeft {
    width: 75%;
    text-align: left;
    margin-top: 0.7em;
  }

  .headerRight {
    color: #005d92;
    background-color: white;
    border: none;
    width: 25%;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: right;
  }
  .details {
    margin-top: 1em;
  }

  .details .row {
    display: flex;
    flex-direction: row;
  }

  .details .row .value {
    flex-grow: 1;
    text-align: right;
    color: black;
    line-height: 2em;
    font-size: 1.6rem;
    white-space: nowrap;
  }

  .details .row .key {
    white-space: nowrap;
    color: #5e554a;
    font-weight: 400;
    line-height: 2em;
    font-size: 1.4rem;
  }
  pn-button {
    border-radius: 2em;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.07);
  }

</style>

