<template>
  <div class="brandingContainer">
    <div class="branding">
      <span>{{ $t('mobilePayment.poweredBy') }}</span>
      <img class="pn"
           :alt="$t('mobilePayment.imgAlt')"
           src="../assets/pn_stralfors_color-positive_rgb.svg"
      />
    </div>
    <a class="mpay" :alt="$t('mobilePayment.alt')" href="/about" target="_blank">{{ $t('mobilePayment.text') }}</a>
  </div>
</template>

<script>
export default {
  name: 'BrandingInfo'
}
</script>

<style scoped>

.brandingContainer {
  position: relative;
  bottom: 0;
  margin-top: 1em;
}

.branding {
  display: flex;
  align-items: baseline;
  gap: 0.3em;
  text-align: left;
  color: #5e554a;
  font-size: 14px;
  margin-bottom: 0.3em;
}

.pn {
  width: 91.93px;
  flex-shrink: 0;
}

.mpay {
  text-align: left;
  font-size: 1.4rem;
}

</style>

<script>

</script>
